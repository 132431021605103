import React from "react";
import {graphql, PageProps, navigate} from "gatsby";
import {Box, FormControl, FormLabel, Grid, Radio, RadioGroup, Typography} from "@mui/material";
import { getSubscription, cancelSubscription, ResignReason } from "services/subscription";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";

const CANCEL_REASONS: { [k in ResignReason]: string } = {
  "spam": "SPAM",
  "too_often": "Za często otrzymuję wiadomości",
  "not_interested": "Nie interesuje mnie treść newslettera",
  "wont_say": "Nie chcę odpowiadać"
}

export default function CancelPage({params}: PageProps) {
  const [subscription, setSubscription] = React.useState(false);
  const [isSubmitted, setSubmitted] = React.useState(false);
  const [reason, setReason] = React.useState<ResignReason | "">("");

  const isValid = !!reason

  const onSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.persist();
    e.preventDefault();

    if (!reason) return;

    try {
      await cancelSubscription(params.subscriptionId, reason);
      setSubmitted(true)
    } catch {
      alert("Nie udało się anulować subskrypcji. Prosimy o kontakt pod adresem it@dokdok.pl")
    }
  }

  React.useEffect(() => {
    (async function() {
      try {
        await getSubscription(params.subscriptionId)
        setSubscription(true)
      } catch {
        navigate("/", { replace: true })
      }
    })()
  }, [])

  if (!subscription) return null

  return (
    <SiteLayout>
      <Section padding={2}>
        <Grid container spacing={[2, 10]} justifyContent="center" my={[2, 10]}>
          <Grid item xs={12}>
            <Typography variant="h1" color="primary" textAlign="center" mb={2}>
              Wypisz z newslettera
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {
              isSubmitted ? (
                <Typography textAlign="center" color="primary">
                  Właśnie zostałeś wypisany z naszego newslettera.
                </Typography>
              ) : (
                <Box
                  component="form"
                  onSubmit={onSubmit}
                  sx={{
                    margin: "0 auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "328px"
                  }}
                >
                  <FormControl>
                    <FormLabel id="reason">Powód wypisania</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="reason"
                      onChange={(e) => setReason(e.target.value as ResignReason)}
                      value={reason}
                    >
                      {
                        Object.entries(CANCEL_REASONS).map(([value, label]) => (
                          <FormControlLabel
                            key={label}
                            value={value}
                            label={label}
                            control={<Radio />}
                          />
                        ))
                      }
                    </RadioGroup>
                  </FormControl>
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={!isValid || isSubmitted}
                    sx={{mb: 2}}
                  >
                    Wypisz mnie
                  </Button>
                </Box>
              )
            }
          </Grid>
        </Grid>
      </Section>
    </SiteLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
